@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Helvetica';
        src: url('./fonts/Helvetica.woff2');
    }

    body {
        @apply text-base lg:text-sm font-['Helvetica'];
    }

    a {
        @apply underline hover:no-underline hover:text-[#0000ff] underline-offset-2 lg:decoration-2 lg:underline-offset-4;
    }

    h1 {
        @apply text-md lg:text-xl font-normal;
    }

    h2 {
        @apply text-[1.5rem] leading-[1.2] tracking-[-.03em] lg:text-[2.125rem];
    }

    select {
        cursor: pointer;
    }

    [name] {
        @apply rounded-none;
    }
}

.swiper-pagination {
    @apply flex flex-wrap;
}

.swiper-slide {
    @apply grid grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 gap-y-5 lg:gap-x-8 !important;
}

.swiper-pagination-bullet {
    @apply underline underline-offset-2 lg:underline-offset-4 lg:decoration-2 cursor-pointer mr-4 last:mr-0 hover:no-underline hover:text-[#0000ff];
}

.swiper-pagination-bullet-active {
    @apply no-underline cursor-auto hover:text-black;
}
